@import 'styles/layers.scss';

@layer uikit {
  .content {
    cursor: pointer;
    background-color: var(--black29);
  }
}

@layer uikitlevel1 {
  .content {
    padding: 12px;
    height: 135px;
  }
}
